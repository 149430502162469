.wrapper-card
{
    background-size: cover;
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 20px;
    z-index: -1;
}


.wrapper-card.collapsed{
    position: fixed;
}


.test-card
{
    background: red;
    border-radius: var(--swipe-card-border-radius);
   margin: 20px 0;
   z-index: 1000;
   height: 20vh;
   width: 100%;
   opacity: 0;

}

.bgg
{
    border-radius: var(--swipe-card-border-radius);
    height: 20vh;
    width: 100%;
    .card-title
    {
        h1
        {
            color: white !important;
            font-family: 'RubikBold';
            text-decoration: underline;
            margin: 0;
        }
    }

    .bottm-holder
    {
        bottom: 10px;
        left: 10px;
        position: absolute;
        width: 100%;
    }

    .caption-title
    {
        color: white;
        font-family: 'RubikMedium';
    }

    .caption-time
    {
        color: rgb(202, 202, 202);
        font-family: 'RubikBold';
        font-size: 12px;
        margin-top: 6px;
    }
}


.test2
{
    border-radius: 10px;
    height: 20vh;
    width: 100%;
}

.test-card , .test2
{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
    ;
}
