@tailwind base;
@tailwind components;
@tailwind utilities;

/* inter-200 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: url('assets/font/inter-v12-latin-200.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url('assets/font/inter-v12-latin-200.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('assets/font/inter-v12-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url('assets/font/inter-v12-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('assets/font/inter-v12-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
    url('assets/font/inter-v12-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face{
    font-family: "Merriweather Sans";
    src: url("assets/font/MerriweatherSans-VariableFont_wght.ttf") format("ttf");
    font-weight:normal;
}

:root, :root[mode="md"], :root[mode="ios"] {
    --ion-font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif !important;
    font-family: var(--ion-font-family) !important;
}

body,html{
    font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif !important;
}

ion-avatar img{
    height:100%;
}

.bg-gradient {
    background: linear-gradient(to top right, var(--color-tinder-stop1) 0%, var(--color-tinder-stop2) 100%);
}


.PhoneInput input{
    background:white
}

body.backdrop{
    background: white;
}

.ios.ion-page .swiper{
    padding-bottom:30px;
    padding-top:30px;
}

.swiper-wrapper{
    font-size:0.8rem !important;
}

.ios .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{

}

ion-buttons.buttons-first-slot.sc-ion-buttons-ios-h.sc-ion-buttons-ios-s.ios {
    padding-left: 1rem ;
}

ion-buttons.buttons-last-slot.sc-ion-buttons-ios-h.sc-ion-buttons-ios-s.ios{
    padding-right: 0.5rem ;
}