.landing-page {
  --color: #fff;

  &.bg-gradient-yellow{
    --background: linear-gradient(to top right, var(--color-tinder-stop1) 0%, var(--color-tinder-stop2) 100%);
  }

  &.bg-gradient {
    --background: linear-gradient(to top right, #000000 0%, #251c0b 100%);
  }

  input[type=email]{
    background: rgba(255,255,255,0.1);
    border-radius: 64px;
    margin-bottom:12px;
  }

  .section-brand {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .section-buttons {
    flex: 0 0 auto;
    text-align: center;
  }

  .logo {
    margin-top: 20vh;
  }

  .logo-icon {
    opacity:0.68;
    height: 140px;
    vertical-align: middle;
  }
  .logo-text {
    vertical-align: middle;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -2px
  }

  .button-clear {
    max-width: 400px;
    margin: 6px auto;
  }

  .social-btns-holder
  {
    ion-button
    {
      margin: 0 0 16px 0;
    }
  }

  ion-button
  {
    height: 3rem;
    ion-icon
    {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
