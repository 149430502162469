.discover-page
{
  .section
  {
    margin-bottom: 20px;
    .title
    {
      margin-bottom: 10px;
      h2
      {
        font-weight: 700;
        margin: 0;
      }

      h4
      {
        font-weight: 500;
        font-size: 16px;
        margin: 10px 0 0 0;
      }
    }

    .card-container
    {
      background: red;
    }
  }
}

