@import './chatui.scss';


.VibeChat .chat-container {
  height: 80vh;
  padding-bottom:80px !important;
  overflow-y:auto;
}

.chat-page {
  .header-custom {
    ion-toolbar {
      --min-height: 66px;
    }
  }

  .user-name {
    font-weight: 400;
    font-size: 12px;
    color: var(--ion-color-medium);
  }

  .match-info {
    font-size: var(--font-size-sm);
  }
}
